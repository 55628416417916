import React from 'react';

import ContactForm from '../components/ContactForm';

export default function ContactPage() {
  return (
    <>
      <ContactForm />
    </>
  );
}
